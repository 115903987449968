@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,600,600italic");

* {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}

textarea {
  resize: none;
}

.fade-anim img {
    animation: fadeAnim 1s ease-out;
  }
  @keyframes fadeAnim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }