#darkContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: radial-gradient(ellipse at bottom, #0c1b30 0%, #1f3558 100%);
  overflow: hidden;
  z-index: 0;
}

#lightContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: radial-gradient(ellipse at bottom, #d9f0fd 0%, #aedffa 100%);
  overflow: hidden;
  z-index: 0;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 436px 1123px #fff, 1541px 1279px #fff, 1291px 982px #fff,
    547px 1980px #fff, 1963px 1048px #fff, 305px 1341px #fff, 1249px 1813px #fff,
    1088px 740px #fff, 1512px 1019px #fff, 330px 576px #fff, 771px 1677px #fff,
    214px 886px #fff, 635px 1518px #fff, 1818px 856px #fff, 1234px 102px #fff,
    1254px 772px #fff, 1466px 608px #fff, 1713px 1133px #fff, 1591px 725px #fff,
    1548px 1175px #fff, 1388px 733px #fff, 1011px 1347px #fff, 1889px 574px #fff,
    1823px 23px #fff, 983px 1802px #fff, 1421px 162px #fff, 857px 98px #fff,
    1239px 891px #fff, 1620px 107px #fff, 1482px 607px #fff, 376px 1636px #fff,
    1873px 93px #fff, 1743px 1697px #fff, 605px 1797px #fff, 425px 1871px #fff,
    810px 1115px #fff, 1582px 1315px #fff, 483px 1116px #fff, 1015px 1736px #fff,
    658px 1121px #fff, 165px 305px #fff, 1127px 327px #fff, 1846px 257px #fff,
    98px 1820px #fff, 1384px 734px #fff, 1374px 902px #fff, 1623px 757px #fff,
    1294px 598px #fff, 1253px 1016px #fff, 1178px 1816px #fff, 1444px 107px #fff,
    1487px 1415px #fff, 1975px 1374px #fff, 600px 1567px #fff,
    1856px 1217px #fff, 378px 319px #fff, 1507px 1671px #fff, 1578px 1268px #fff,
    1389px 297px #fff, 1440px 1500px #fff, 1398px 884px #fff, 280px 453px #fff,
    534px 1458px #fff, 852px 792px #fff, 582px 338px #fff, 1293px 1712px #fff,
    701px 1696px #fff, 1495px 1310px #fff, 1135px 1956px #fff, 637px 1839px #fff,
    558px 1617px #fff, 590px 164px #fff, 942px 1877px #fff, 1768px 433px #fff,
    666px 991px #fff, 881px 351px #fff, 481px 398px #fff, 1343px 938px #fff,
    1375px 583px #fff, 1845px 1421px #fff, 256px 572px #fff, 370px 1998px #fff,
    788px 1251px #fff, 1690px 1079px #fff, 1219px 1676px #fff, 208px 1479px #fff,
    266px 733px #fff, 966px 243px #fff, 1015px 1153px #fff, 1305px 505px #fff,
    1929px 1471px #fff, 1390px 1815px #fff, 1064px 1124px #fff,
    1101px 1004px #fff, 68px 1533px #fff, 501px 1296px #fff, 1147px 339px #fff,
    1569px 1809px #fff, 1191px 1468px #fff, 1146px 1902px #fff,
    1489px 611px #fff, 1719px 1916px #fff, 1975px 1278px #fff, 993px 96px #fff,
    1021px 149px #fff, 376px 97px #fff, 838px 287px #fff, 1462px 758px #fff,
    1701px 648px #fff, 1165px 1960px #fff, 301px 1028px #fff, 1539px 1492px #fff,
    1186px 868px #fff, 171px 1087px #fff, 1443px 1032px #fff, 1652px 419px #fff,
    450px 1003px #fff, 1436px 836px #fff, 1943px 111px #fff, 1272px 138px #fff,
    1428px 63px #fff, 394px 1994px #fff, 1506px 518px #fff, 929px 467px #fff,
    278px 650px #fff, 825px 1392px #fff, 1011px 1180px #fff, 1764px 1729px #fff,
    320px 1277px #fff, 1919px 1642px #fff, 645px 514px #fff, 1772px 376px #fff,
    1030px 1822px #fff, 843px 238px #fff, 1613px 1777px #fff, 241px 756px #fff,
    1283px 72px #fff, 974px 1054px #fff, 1724px 1829px #fff, 138px 18px #fff,
    1717px 956px #fff, 1353px 93px #fff, 1411px 1447px #fff, 396px 1150px #fff,
    200px 652px #fff, 1620px 439px #fff, 1237px 1005px #fff, 1923px 186px #fff,
    498px 783px #fff, 810px 1975px #fff, 1989px 404px #fff, 1798px 966px #fff,
    1630px 1036px #fff, 683px 263px #fff, 1351px 1140px #fff, 550px 1689px #fff,
    399px 1374px #fff, 1661px 1075px #fff, 1033px 686px #fff, 1629px 1941px #fff,
    1006px 1055px #fff, 829px 875px #fff, 1979px 1611px #fff, 1313px 679px #fff,
    1138px 684px #fff, 753px 734px #fff, 676px 1076px #fff, 471px 748px #fff,
    826px 1671px #fff, 206px 1526px #fff, 647px 81px #fff, 1484px 1525px #fff,
    979px 857px #fff, 162px 1509px #fff, 700px 549px #fff, 1760px 1252px #fff,
    1789px 621px #fff, 1103px 693px #fff, 338px 59px #fff, 514px 74px #fff,
    1249px 1611px #fff, 1311px 1733px #fff, 409px 1815px #fff, 935px 1669px #fff,
    1407px 1127px #fff, 1713px 683px #fff, 489px 271px #fff, 700px 1999px #fff,
    161px 835px #fff, 74px 1182px #fff, 155px 986px #fff, 968px 277px #fff,
    107px 87px #fff, 1843px 1377px #fff, 1318px 1212px #fff, 1980px 535px #fff,
    1297px 1158px #fff, 237px 1287px #fff, 1042px 635px #fff, 404px 1704px #fff,
    1695px 989px #fff, 126px 1821px #fff, 748px 1698px #fff, 1097px 1874px #fff,
    1026px 484px #fff, 727px 1502px #fff, 1822px 955px #fff, 1694px 1244px #fff,
    95px 1919px #fff, 1587px 334px #fff, 1141px 314px #fff, 1575px 406px #fff,
    321px 1522px #fff, 403px 83px #fff, 797px 1762px #fff, 913px 1094px #fff,
    186px 917px #fff, 1204px 313px #fff, 1834px 1321px #fff, 1034px 1059px #fff,
    1637px 436px #fff, 802px 349px #fff, 229px 1660px #fff, 1376px 1116px #fff,
    296px 455px #fff, 302px 1968px #fff, 1887px 1680px #fff, 753px 599px #fff,
    1042px 149px #fff, 1617px 258px #fff, 704px 266px #fff, 1367px 630px #fff,
    855px 499px #fff, 1050px 1568px #fff, 492px 1136px #fff, 15px 1809px #fff,
    661px 1268px #fff, 1983px 1427px #fff, 782px 911px #fff, 418px 317px #fff,
    866px 1101px #fff, 1360px 1033px #fff, 1543px 1737px #fff, 253px 64px #fff,
    435px 291px #fff, 1979px 1014px #fff, 476px 1023px #fff, 582px 1458px #fff,
    1487px 1270px #fff, 1694px 1043px #fff, 1391px 1357px #fff,
    447px 1403px #fff, 280px 1275px #fff, 1248px 411px #fff, 1243px 465px #fff,
    282px 1340px #fff, 1764px 1236px #fff, 1005px 65px #fff, 297px 333px #fff,
    1326px 1828px #fff, 1588px 135px #fff, 109px 1646px #fff, 1798px 1977px #fff,
    1813px 1579px #fff, 225px 1154px #fff, 266px 1978px #fff, 1853px 1380px #fff,
    5px 1845px #fff, 582px 823px #fff, 1137px 1201px #fff, 836px 1219px #fff,
    1779px 91px #fff, 1192px 1464px #fff, 15px 76px #fff, 110px 1014px #fff,
    594px 1513px #fff, 1801px 150px #fff, 425px 55px #fff, 588px 292px #fff,
    1386px 537px #fff, 829px 849px #fff, 1893px 537px #fff, 359px 1849px #fff,
    541px 1772px #fff, 739px 874px #fff, 1729px 1384px #fff, 51px 1771px #fff,
    1160px 847px #fff, 896px 1828px #fff, 1359px 1447px #fff, 1627px 1550px #fff,
    558px 1514px #fff, 1618px 807px #fff, 927px 423px #fff, 602px 451px #fff,
    696px 1093px #fff, 133px 619px #fff, 1157px 1660px #fff, 574px 1154px #fff,
    837px 1621px #fff, 944px 601px #fff, 733px 389px #fff, 1914px 1875px #fff,
    857px 529px #fff, 1877px 911px #fff, 408px 262px #fff, 920px 1922px #fff,
    956px 352px #fff, 911px 1934px #fff, 193px 929px #fff, 583px 478px #fff,
    1471px 691px #fff, 757px 1000px #fff, 647px 1981px #fff, 967px 1015px #fff,
    1516px 1935px #fff, 1321px 797px #fff, 979px 207px #fff, 1197px 1936px #fff,
    1252px 421px #fff, 1724px 1300px #fff, 746px 1250px #fff, 707px 1509px #fff,
    845px 1150px #fff, 859px 1420px #fff, 308px 1726px #fff, 1210px 867px #fff,
    307px 702px #fff, 104px 655px #fff, 734px 555px #fff, 1043px 809px #fff,
    586px 829px #fff, 1365px 378px #fff, 598px 1189px #fff, 5px 1390px #fff,
    70px 644px #fff, 1062px 376px #fff, 270px 1364px #fff, 297px 2000px #fff,
    843px 667px #fff, 637px 1064px #fff, 713px 859px #fff, 49px 222px #fff,
    1391px 275px #fff, 1747px 667px #fff, 1403px 1906px #fff, 1781px 1965px #fff,
    1499px 708px #fff, 1920px 145px #fff, 1262px 1665px #fff, 1730px 1699px #fff,
    1200px 74px #fff, 516px 1524px #fff, 1590px 1924px #fff, 1657px 1283px #fff,
    154px 91px #fff, 1017px 1557px #fff, 1061px 1689px #fff, 1515px 184px #fff,
    1086px 1282px #fff, 68px 763px #fff, 1202px 624px #fff, 1161px 1690px #fff,
    233px 329px #fff, 263px 1156px #fff, 1712px 475px #fff, 1096px 222px #fff,
    1085px 1454px #fff, 1099px 1989px #fff, 965px 1947px #fff, 1097px 793px #fff,
    427px 1365px #fff, 269px 1699px #fff, 1910px 511px #fff, 191px 1779px #fff,
    938px 1653px #fff, 293px 884px #fff, 1331px 668px #fff, 719px 522px #fff,
    245px 550px #fff, 753px 599px #fff, 928px 1862px #fff, 560px 1677px #fff,
    855px 1241px #fff, 1260px 699px #fff, 1450px 1889px #fff, 999px 874px #fff,
    437px 1508px #fff, 1794px 339px #fff, 544px 1919px #fff, 853px 1435px #fff,
    1779px 473px #fff, 315px 1489px #fff, 1822px 140px #fff, 1789px 1198px #fff,
    406px 1214px #fff, 1563px 1294px #fff, 314px 1062px #fff, 1903px 1401px #fff,
    335px 1670px #fff, 643px 142px #fff, 1255px 1683px #fff, 1508px 1979px #fff,
    1373px 779px #fff, 1417px 730px #fff, 1203px 1659px #fff, 1505px 813px #fff,
    1626px 1504px #fff, 1760px 371px #fff, 1805px 1042px #fff, 181px 124px #fff,
    906px 10px #fff, 1620px 1433px #fff, 1596px 59px #fff, 1164px 617px #fff,
    1888px 786px #fff, 643px 556px #fff, 1913px 1890px #fff, 1130px 943px #fff,
    760px 1982px #fff, 1750px 281px #fff, 1258px 1387px #fff, 1065px 237px #fff,
    492px 103px #fff, 360px 610px #fff, 699px 1543px #fff, 488px 586px #fff,
    721px 1717px #fff, 1697px 213px #fff, 1148px 1859px #fff, 363px 851px #fff,
    703px 1110px #fff, 1464px 1777px #fff, 1645px 821px #fff, 29px 330px #fff,
    1292px 400px #fff, 1858px 283px #fff, 606px 1355px #fff, 1910px 960px #fff,
    1400px 1750px #fff, 856px 1512px #fff, 1700px 1959px #fff, 1376px 639px #fff,
    898px 1680px #fff, 1930px 629px #fff, 597px 449px #fff, 321px 172px #fff,
    35px 1434px #fff, 1316px 1612px #fff, 174px 611px #fff, 709px 1109px #fff,
    278px 1913px #fff, 200px 478px #fff, 1257px 1645px #fff, 218px 388px #fff,
    306px 1595px #fff, 1154px 1703px #fff, 1919px 750px #fff, 209px 1168px #fff,
    1631px 705px #fff, 1187px 577px #fff, 1766px 1440px #fff, 867px 502px #fff,
    636px 679px #fff, 481px 108px #fff, 1341px 1832px #fff, 602px 1028px #fff,
    834px 219px #fff, 716px 1257px #fff, 1231px 1977px #fff, 1493px 1683px #fff,
    118px 911px #fff, 353px 646px #fff, 708px 804px #fff, 284px 1741px #fff,
    1505px 1353px #fff, 75px 1910px #fff, 1045px 1789px #fff, 1496px 48px #fff,
    148px 1375px #fff, 930px 1358px #fff, 747px 1492px #fff, 320px 394px #fff,
    536px 1625px #fff, 1026px 1992px #fff, 74px 1638px #fff, 1599px 1193px #fff,
    572px 1532px #fff, 856px 1006px #fff, 658px 1114px #fff, 46px 1656px #fff,
    654px 1341px #fff, 1118px 1807px #fff, 1236px 1165px #fff, 1101px 680px #fff,
    1775px 962px #fff, 1033px 1891px #fff, 1688px 1720px #fff,
    1854px 1180px #fff, 1625px 1373px #fff, 114px 929px #fff, 655px 1079px #fff,
    898px 233px #fff, 1835px 1172px #fff, 1142px 268px #fff, 1245px 1972px #fff,
    1475px 1796px #fff, 1854px 1156px #fff, 763px 1659px #fff, 1721px 495px #fff,
    917px 528px #fff, 1542px 777px #fff, 1830px 1513px #fff, 1583px 849px #fff,
    1391px 1054px #fff, 332px 1756px #fff, 539px 1218px #fff, 1655px 64px #fff,
    1508px 957px #fff, 932px 969px #fff, 399px 1966px #fff, 1099px 431px #fff,
    814px 857px #fff, 591px 628px #fff, 477px 1429px #fff, 1881px 1013px #fff,
    410px 1178px #fff, 90px 1177px #fff, 1160px 1333px #fff, 1851px 1365px #fff,
    1383px 1589px #fff, 284px 646px #fff, 494px 821px #fff, 258px 178px #fff,
    420px 1584px #fff, 854px 1756px #fff, 1596px 128px #fff, 1595px 1900px #fff,
    1004px 793px #fff, 1707px 1024px #fff, 180px 1633px #fff, 726px 678px #fff,
    948px 726px #fff, 917px 1363px #fff, 1159px 1718px #fff, 1804px 4px #fff,
    598px 360px #fff, 1962px 279px #fff, 961px 768px #fff, 669px 1333px #fff,
    1136px 121px #fff, 1319px 481px #fff, 9px 1237px #fff, 972px 1874px #fff,
    228px 1878px #fff, 602px 479px #fff, 1763px 617px #fff, 1771px 1690px #fff,
    360px 195px #fff, 1027px 561px #fff, 760px 1824px #fff, 1398px 1137px #fff,
    1074px 958px #fff, 414px 466px #fff, 1781px 995px #fff, 687px 167px #fff,
    156px 842px #fff, 938px 409px #fff, 247px 875px #fff, 1721px 720px #fff,
    1686px 1979px #fff, 749px 1829px #fff, 700px 1483px #fff, 595px 948px #fff,
    932px 22px #fff, 375px 238px #fff, 1561px 1815px #fff, 368px 1853px #fff,
    142px 1214px #fff, 1597px 967px #fff, 8px 1042px #fff, 1775px 601px #fff,
    1519px 1139px #fff, 54px 888px #fff, 543px 1545px #fff, 1874px 1505px #fff,
    339px 147px #fff, 999px 1156px #fff, 1317px 370px #fff, 1525px 1090px #fff,
    601px 1650px #fff, 1733px 1067px #fff, 135px 492px #fff, 1243px 1441px #fff,
    1979px 1938px #fff, 189px 1890px #fff, 1989px 644px #fff, 1064px 1916px #fff,
    1708px 1949px #fff, 232px 1319px #fff, 1135px 472px #fff, 83px 1241px #fff,
    657px 931px #fff, 1508px 610px #fff, 114px 164px #fff, 1404px 1970px #fff,
    1198px 666px #fff, 1932px 1774px #fff, 478px 934px #fff, 831px 1302px #fff,
    1176px 593px #fff, 337px 1119px #fff, 1980px 37px #fff, 953px 239px #fff,
    446px 876px #fff, 561px 1385px #fff, 324px 977px #fff, 1982px 1684px #fff,
    873px 1400px #fff, 1396px 1396px #fff, 374px 1234px #fff, 1806px 6px #fff,
    1306px 685px #fff, 783px 45px #fff, 1645px 572px #fff, 1663px 1482px #fff,
    1805px 1997px #fff, 1978px 1971px #fff, 1417px 1120px #fff,
    529px 1864px #fff, 1748px 1986px #fff, 336px 771px #fff, 1928px 118px #fff,
    1618px 624px #fff, 1980px 197px #fff, 1591px 1447px #fff, 1388px 1596px #fff,
    51px 1447px #fff, 1769px 463px #fff, 695px 1948px #fff, 1px 941px #fff,
    816px 44px #fff, 550px 1171px #fff, 88px 12px #fff, 1242px 1866px #fff,
    1978px 1808px #fff, 777px 1347px #fff, 1688px 1796px #fff, 267px 689px #fff,
    755px 1882px #fff, 21px 232px #fff, 913px 61px #fff, 490px 1472px #fff,
    137px 1509px #fff, 1706px 1493px #fff, 1906px 442px #fff, 1695px 678px #fff,
    749px 1105px #fff, 364px 662px #fff, 1670px 902px #fff, 203px 667px #fff,
    861px 482px #fff, 406px 585px #fff, 589px 1158px #fff, 1674px 1039px #fff,
    1028px 1808px #fff, 1995px 371px #fff, 1866px 1345px #fff, 1482px 528px #fff,
    1880px 450px #fff, 223px 1288px #fff, 905px 1612px #fff, 487px 908px #fff,
    1436px 1552px #fff, 512px 871px #fff, 584px 537px #fff, 1121px 915px #fff,
    986px 1612px #fff, 1857px 299px #fff, 1454px 1423px #fff, 1826px 1721px #fff,
    1334px 154px #fff, 832px 1501px #fff, 390px 1529px #fff, 853px 1028px #fff,
    705px 1860px #fff, 379px 1781px #fff, 1666px 179px #fff, 255px 799px #fff,
    1661px 1381px #fff, 1686px 183px #fff, 1256px 935px #fff, 1399px 1289px #fff,
    979px 396px #fff, 635px 1144px #fff, 1098px 1490px #fff, 249px 1000px #fff,
    772px 168px #fff, 1382px 921px #fff, 1667px 842px #fff;
  animation: animStar 50s linear infinite;
}

#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 436px 1123px #fff, 1541px 1279px #fff, 1291px 982px #fff,
    547px 1980px #fff, 1963px 1048px #fff, 305px 1341px #fff, 1249px 1813px #fff,
    1088px 740px #fff, 1512px 1019px #fff, 330px 576px #fff, 771px 1677px #fff,
    214px 886px #fff, 635px 1518px #fff, 1818px 856px #fff, 1234px 102px #fff,
    1254px 772px #fff, 1466px 608px #fff, 1713px 1133px #fff, 1591px 725px #fff,
    1548px 1175px #fff, 1388px 733px #fff, 1011px 1347px #fff, 1889px 574px #fff,
    1823px 23px #fff, 983px 1802px #fff, 1421px 162px #fff, 857px 98px #fff,
    1239px 891px #fff, 1620px 107px #fff, 1482px 607px #fff, 376px 1636px #fff,
    1873px 93px #fff, 1743px 1697px #fff, 605px 1797px #fff, 425px 1871px #fff,
    810px 1115px #fff, 1582px 1315px #fff, 483px 1116px #fff, 1015px 1736px #fff,
    658px 1121px #fff, 165px 305px #fff, 1127px 327px #fff, 1846px 257px #fff,
    98px 1820px #fff, 1384px 734px #fff, 1374px 902px #fff, 1623px 757px #fff,
    1294px 598px #fff, 1253px 1016px #fff, 1178px 1816px #fff, 1444px 107px #fff,
    1487px 1415px #fff, 1975px 1374px #fff, 600px 1567px #fff,
    1856px 1217px #fff, 378px 319px #fff, 1507px 1671px #fff, 1578px 1268px #fff,
    1389px 297px #fff, 1440px 1500px #fff, 1398px 884px #fff, 280px 453px #fff,
    534px 1458px #fff, 852px 792px #fff, 582px 338px #fff, 1293px 1712px #fff,
    701px 1696px #fff, 1495px 1310px #fff, 1135px 1956px #fff, 637px 1839px #fff,
    558px 1617px #fff, 590px 164px #fff, 942px 1877px #fff, 1768px 433px #fff,
    666px 991px #fff, 881px 351px #fff, 481px 398px #fff, 1343px 938px #fff,
    1375px 583px #fff, 1845px 1421px #fff, 256px 572px #fff, 370px 1998px #fff,
    788px 1251px #fff, 1690px 1079px #fff, 1219px 1676px #fff, 208px 1479px #fff,
    266px 733px #fff, 966px 243px #fff, 1015px 1153px #fff, 1305px 505px #fff,
    1929px 1471px #fff, 1390px 1815px #fff, 1064px 1124px #fff,
    1101px 1004px #fff, 68px 1533px #fff, 501px 1296px #fff, 1147px 339px #fff,
    1569px 1809px #fff, 1191px 1468px #fff, 1146px 1902px #fff,
    1489px 611px #fff, 1719px 1916px #fff, 1975px 1278px #fff, 993px 96px #fff,
    1021px 149px #fff, 376px 97px #fff, 838px 287px #fff, 1462px 758px #fff,
    1701px 648px #fff, 1165px 1960px #fff, 301px 1028px #fff, 1539px 1492px #fff,
    1186px 868px #fff, 171px 1087px #fff, 1443px 1032px #fff, 1652px 419px #fff,
    450px 1003px #fff, 1436px 836px #fff, 1943px 111px #fff, 1272px 138px #fff,
    1428px 63px #fff, 394px 1994px #fff, 1506px 518px #fff, 929px 467px #fff,
    278px 650px #fff, 825px 1392px #fff, 1011px 1180px #fff, 1764px 1729px #fff,
    320px 1277px #fff, 1919px 1642px #fff, 645px 514px #fff, 1772px 376px #fff,
    1030px 1822px #fff, 843px 238px #fff, 1613px 1777px #fff, 241px 756px #fff,
    1283px 72px #fff, 974px 1054px #fff, 1724px 1829px #fff, 138px 18px #fff,
    1717px 956px #fff, 1353px 93px #fff, 1411px 1447px #fff, 396px 1150px #fff,
    200px 652px #fff, 1620px 439px #fff, 1237px 1005px #fff, 1923px 186px #fff,
    498px 783px #fff, 810px 1975px #fff, 1989px 404px #fff, 1798px 966px #fff,
    1630px 1036px #fff, 683px 263px #fff, 1351px 1140px #fff, 550px 1689px #fff,
    399px 1374px #fff, 1661px 1075px #fff, 1033px 686px #fff, 1629px 1941px #fff,
    1006px 1055px #fff, 829px 875px #fff, 1979px 1611px #fff, 1313px 679px #fff,
    1138px 684px #fff, 753px 734px #fff, 676px 1076px #fff, 471px 748px #fff,
    826px 1671px #fff, 206px 1526px #fff, 647px 81px #fff, 1484px 1525px #fff,
    979px 857px #fff, 162px 1509px #fff, 700px 549px #fff, 1760px 1252px #fff,
    1789px 621px #fff, 1103px 693px #fff, 338px 59px #fff, 514px 74px #fff,
    1249px 1611px #fff, 1311px 1733px #fff, 409px 1815px #fff, 935px 1669px #fff,
    1407px 1127px #fff, 1713px 683px #fff, 489px 271px #fff, 700px 1999px #fff,
    161px 835px #fff, 74px 1182px #fff, 155px 986px #fff, 968px 277px #fff,
    107px 87px #fff, 1843px 1377px #fff, 1318px 1212px #fff, 1980px 535px #fff,
    1297px 1158px #fff, 237px 1287px #fff, 1042px 635px #fff, 404px 1704px #fff,
    1695px 989px #fff, 126px 1821px #fff, 748px 1698px #fff, 1097px 1874px #fff,
    1026px 484px #fff, 727px 1502px #fff, 1822px 955px #fff, 1694px 1244px #fff,
    95px 1919px #fff, 1587px 334px #fff, 1141px 314px #fff, 1575px 406px #fff,
    321px 1522px #fff, 403px 83px #fff, 797px 1762px #fff, 913px 1094px #fff,
    186px 917px #fff, 1204px 313px #fff, 1834px 1321px #fff, 1034px 1059px #fff,
    1637px 436px #fff, 802px 349px #fff, 229px 1660px #fff, 1376px 1116px #fff,
    296px 455px #fff, 302px 1968px #fff, 1887px 1680px #fff, 753px 599px #fff,
    1042px 149px #fff, 1617px 258px #fff, 704px 266px #fff, 1367px 630px #fff,
    855px 499px #fff, 1050px 1568px #fff, 492px 1136px #fff, 15px 1809px #fff,
    661px 1268px #fff, 1983px 1427px #fff, 782px 911px #fff, 418px 317px #fff,
    866px 1101px #fff, 1360px 1033px #fff, 1543px 1737px #fff, 253px 64px #fff,
    435px 291px #fff, 1979px 1014px #fff, 476px 1023px #fff, 582px 1458px #fff,
    1487px 1270px #fff, 1694px 1043px #fff, 1391px 1357px #fff,
    447px 1403px #fff, 280px 1275px #fff, 1248px 411px #fff, 1243px 465px #fff,
    282px 1340px #fff, 1764px 1236px #fff, 1005px 65px #fff, 297px 333px #fff,
    1326px 1828px #fff, 1588px 135px #fff, 109px 1646px #fff, 1798px 1977px #fff,
    1813px 1579px #fff, 225px 1154px #fff, 266px 1978px #fff, 1853px 1380px #fff,
    5px 1845px #fff, 582px 823px #fff, 1137px 1201px #fff, 836px 1219px #fff,
    1779px 91px #fff, 1192px 1464px #fff, 15px 76px #fff, 110px 1014px #fff,
    594px 1513px #fff, 1801px 150px #fff, 425px 55px #fff, 588px 292px #fff,
    1386px 537px #fff, 829px 849px #fff, 1893px 537px #fff, 359px 1849px #fff,
    541px 1772px #fff, 739px 874px #fff, 1729px 1384px #fff, 51px 1771px #fff,
    1160px 847px #fff, 896px 1828px #fff, 1359px 1447px #fff, 1627px 1550px #fff,
    558px 1514px #fff, 1618px 807px #fff, 927px 423px #fff, 602px 451px #fff,
    696px 1093px #fff, 133px 619px #fff, 1157px 1660px #fff, 574px 1154px #fff,
    837px 1621px #fff, 944px 601px #fff, 733px 389px #fff, 1914px 1875px #fff,
    857px 529px #fff, 1877px 911px #fff, 408px 262px #fff, 920px 1922px #fff,
    956px 352px #fff, 911px 1934px #fff, 193px 929px #fff, 583px 478px #fff,
    1471px 691px #fff, 757px 1000px #fff, 647px 1981px #fff, 967px 1015px #fff,
    1516px 1935px #fff, 1321px 797px #fff, 979px 207px #fff, 1197px 1936px #fff,
    1252px 421px #fff, 1724px 1300px #fff, 746px 1250px #fff, 707px 1509px #fff,
    845px 1150px #fff, 859px 1420px #fff, 308px 1726px #fff, 1210px 867px #fff,
    307px 702px #fff, 104px 655px #fff, 734px 555px #fff, 1043px 809px #fff,
    586px 829px #fff, 1365px 378px #fff, 598px 1189px #fff, 5px 1390px #fff,
    70px 644px #fff, 1062px 376px #fff, 270px 1364px #fff, 297px 2000px #fff,
    843px 667px #fff, 637px 1064px #fff, 713px 859px #fff, 49px 222px #fff,
    1391px 275px #fff, 1747px 667px #fff, 1403px 1906px #fff, 1781px 1965px #fff,
    1499px 708px #fff, 1920px 145px #fff, 1262px 1665px #fff, 1730px 1699px #fff,
    1200px 74px #fff, 516px 1524px #fff, 1590px 1924px #fff, 1657px 1283px #fff,
    154px 91px #fff, 1017px 1557px #fff, 1061px 1689px #fff, 1515px 184px #fff,
    1086px 1282px #fff, 68px 763px #fff, 1202px 624px #fff, 1161px 1690px #fff,
    233px 329px #fff, 263px 1156px #fff, 1712px 475px #fff, 1096px 222px #fff,
    1085px 1454px #fff, 1099px 1989px #fff, 965px 1947px #fff, 1097px 793px #fff,
    427px 1365px #fff, 269px 1699px #fff, 1910px 511px #fff, 191px 1779px #fff,
    938px 1653px #fff, 293px 884px #fff, 1331px 668px #fff, 719px 522px #fff,
    245px 550px #fff, 753px 599px #fff, 928px 1862px #fff, 560px 1677px #fff,
    855px 1241px #fff, 1260px 699px #fff, 1450px 1889px #fff, 999px 874px #fff,
    437px 1508px #fff, 1794px 339px #fff, 544px 1919px #fff, 853px 1435px #fff,
    1779px 473px #fff, 315px 1489px #fff, 1822px 140px #fff, 1789px 1198px #fff,
    406px 1214px #fff, 1563px 1294px #fff, 314px 1062px #fff, 1903px 1401px #fff,
    335px 1670px #fff, 643px 142px #fff, 1255px 1683px #fff, 1508px 1979px #fff,
    1373px 779px #fff, 1417px 730px #fff, 1203px 1659px #fff, 1505px 813px #fff,
    1626px 1504px #fff, 1760px 371px #fff, 1805px 1042px #fff, 181px 124px #fff,
    906px 10px #fff, 1620px 1433px #fff, 1596px 59px #fff, 1164px 617px #fff,
    1888px 786px #fff, 643px 556px #fff, 1913px 1890px #fff, 1130px 943px #fff,
    760px 1982px #fff, 1750px 281px #fff, 1258px 1387px #fff, 1065px 237px #fff,
    492px 103px #fff, 360px 610px #fff, 699px 1543px #fff, 488px 586px #fff,
    721px 1717px #fff, 1697px 213px #fff, 1148px 1859px #fff, 363px 851px #fff,
    703px 1110px #fff, 1464px 1777px #fff, 1645px 821px #fff, 29px 330px #fff,
    1292px 400px #fff, 1858px 283px #fff, 606px 1355px #fff, 1910px 960px #fff,
    1400px 1750px #fff, 856px 1512px #fff, 1700px 1959px #fff, 1376px 639px #fff,
    898px 1680px #fff, 1930px 629px #fff, 597px 449px #fff, 321px 172px #fff,
    35px 1434px #fff, 1316px 1612px #fff, 174px 611px #fff, 709px 1109px #fff,
    278px 1913px #fff, 200px 478px #fff, 1257px 1645px #fff, 218px 388px #fff,
    306px 1595px #fff, 1154px 1703px #fff, 1919px 750px #fff, 209px 1168px #fff,
    1631px 705px #fff, 1187px 577px #fff, 1766px 1440px #fff, 867px 502px #fff,
    636px 679px #fff, 481px 108px #fff, 1341px 1832px #fff, 602px 1028px #fff,
    834px 219px #fff, 716px 1257px #fff, 1231px 1977px #fff, 1493px 1683px #fff,
    118px 911px #fff, 353px 646px #fff, 708px 804px #fff, 284px 1741px #fff,
    1505px 1353px #fff, 75px 1910px #fff, 1045px 1789px #fff, 1496px 48px #fff,
    148px 1375px #fff, 930px 1358px #fff, 747px 1492px #fff, 320px 394px #fff,
    536px 1625px #fff, 1026px 1992px #fff, 74px 1638px #fff, 1599px 1193px #fff,
    572px 1532px #fff, 856px 1006px #fff, 658px 1114px #fff, 46px 1656px #fff,
    654px 1341px #fff, 1118px 1807px #fff, 1236px 1165px #fff, 1101px 680px #fff,
    1775px 962px #fff, 1033px 1891px #fff, 1688px 1720px #fff,
    1854px 1180px #fff, 1625px 1373px #fff, 114px 929px #fff, 655px 1079px #fff,
    898px 233px #fff, 1835px 1172px #fff, 1142px 268px #fff, 1245px 1972px #fff,
    1475px 1796px #fff, 1854px 1156px #fff, 763px 1659px #fff, 1721px 495px #fff,
    917px 528px #fff, 1542px 777px #fff, 1830px 1513px #fff, 1583px 849px #fff,
    1391px 1054px #fff, 332px 1756px #fff, 539px 1218px #fff, 1655px 64px #fff,
    1508px 957px #fff, 932px 969px #fff, 399px 1966px #fff, 1099px 431px #fff,
    814px 857px #fff, 591px 628px #fff, 477px 1429px #fff, 1881px 1013px #fff,
    410px 1178px #fff, 90px 1177px #fff, 1160px 1333px #fff, 1851px 1365px #fff,
    1383px 1589px #fff, 284px 646px #fff, 494px 821px #fff, 258px 178px #fff,
    420px 1584px #fff, 854px 1756px #fff, 1596px 128px #fff, 1595px 1900px #fff,
    1004px 793px #fff, 1707px 1024px #fff, 180px 1633px #fff, 726px 678px #fff,
    948px 726px #fff, 917px 1363px #fff, 1159px 1718px #fff, 1804px 4px #fff,
    598px 360px #fff, 1962px 279px #fff, 961px 768px #fff, 669px 1333px #fff,
    1136px 121px #fff, 1319px 481px #fff, 9px 1237px #fff, 972px 1874px #fff,
    228px 1878px #fff, 602px 479px #fff, 1763px 617px #fff, 1771px 1690px #fff,
    360px 195px #fff, 1027px 561px #fff, 760px 1824px #fff, 1398px 1137px #fff,
    1074px 958px #fff, 414px 466px #fff, 1781px 995px #fff, 687px 167px #fff,
    156px 842px #fff, 938px 409px #fff, 247px 875px #fff, 1721px 720px #fff,
    1686px 1979px #fff, 749px 1829px #fff, 700px 1483px #fff, 595px 948px #fff,
    932px 22px #fff, 375px 238px #fff, 1561px 1815px #fff, 368px 1853px #fff,
    142px 1214px #fff, 1597px 967px #fff, 8px 1042px #fff, 1775px 601px #fff,
    1519px 1139px #fff, 54px 888px #fff, 543px 1545px #fff, 1874px 1505px #fff,
    339px 147px #fff, 999px 1156px #fff, 1317px 370px #fff, 1525px 1090px #fff,
    601px 1650px #fff, 1733px 1067px #fff, 135px 492px #fff, 1243px 1441px #fff,
    1979px 1938px #fff, 189px 1890px #fff, 1989px 644px #fff, 1064px 1916px #fff,
    1708px 1949px #fff, 232px 1319px #fff, 1135px 472px #fff, 83px 1241px #fff,
    657px 931px #fff, 1508px 610px #fff, 114px 164px #fff, 1404px 1970px #fff,
    1198px 666px #fff, 1932px 1774px #fff, 478px 934px #fff, 831px 1302px #fff,
    1176px 593px #fff, 337px 1119px #fff, 1980px 37px #fff, 953px 239px #fff,
    446px 876px #fff, 561px 1385px #fff, 324px 977px #fff, 1982px 1684px #fff,
    873px 1400px #fff, 1396px 1396px #fff, 374px 1234px #fff, 1806px 6px #fff,
    1306px 685px #fff, 783px 45px #fff, 1645px 572px #fff, 1663px 1482px #fff,
    1805px 1997px #fff, 1978px 1971px #fff, 1417px 1120px #fff,
    529px 1864px #fff, 1748px 1986px #fff, 336px 771px #fff, 1928px 118px #fff,
    1618px 624px #fff, 1980px 197px #fff, 1591px 1447px #fff, 1388px 1596px #fff,
    51px 1447px #fff, 1769px 463px #fff, 695px 1948px #fff, 1px 941px #fff,
    816px 44px #fff, 550px 1171px #fff, 88px 12px #fff, 1242px 1866px #fff,
    1978px 1808px #fff, 777px 1347px #fff, 1688px 1796px #fff, 267px 689px #fff,
    755px 1882px #fff, 21px 232px #fff, 913px 61px #fff, 490px 1472px #fff,
    137px 1509px #fff, 1706px 1493px #fff, 1906px 442px #fff, 1695px 678px #fff,
    749px 1105px #fff, 364px 662px #fff, 1670px 902px #fff, 203px 667px #fff,
    861px 482px #fff, 406px 585px #fff, 589px 1158px #fff, 1674px 1039px #fff,
    1028px 1808px #fff, 1995px 371px #fff, 1866px 1345px #fff, 1482px 528px #fff,
    1880px 450px #fff, 223px 1288px #fff, 905px 1612px #fff, 487px 908px #fff,
    1436px 1552px #fff, 512px 871px #fff, 584px 537px #fff, 1121px 915px #fff,
    986px 1612px #fff, 1857px 299px #fff, 1454px 1423px #fff, 1826px 1721px #fff,
    1334px 154px #fff, 832px 1501px #fff, 390px 1529px #fff, 853px 1028px #fff,
    705px 1860px #fff, 379px 1781px #fff, 1666px 179px #fff, 255px 799px #fff,
    1661px 1381px #fff, 1686px 183px #fff, 1256px 935px #fff, 1399px 1289px #fff,
    979px 396px #fff, 635px 1144px #fff, 1098px 1490px #fff, 249px 1000px #fff,
    772px 168px #fff, 1382px 921px #fff, 1667px 842px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 259px 68px #fff, 609px 228px #fff, 604px 589px #fff,
    1391px 644px #fff, 811px 1632px #fff, 934px 1300px #fff, 898px 1184px #fff,
    900px 1532px #fff, 762px 1068px #fff, 1216px 1899px #fff, 1880px 308px #fff,
    1607px 552px #fff, 652px 124px #fff, 1653px 378px #fff, 1155px 800px #fff,
    1275px 361px #fff, 1197px 260px #fff, 1725px 1456px #fff, 410px 523px #fff,
    373px 259px #fff, 992px 1913px #fff, 180px 900px #fff, 89px 914px #fff,
    1269px 570px #fff, 569px 34px #fff, 778px 296px #fff, 239px 1953px #fff,
    906px 1313px #fff, 641px 604px #fff, 1313px 1549px #fff, 590px 261px #fff,
    1953px 471px #fff, 1666px 1648px #fff, 588px 1593px #fff, 1134px 1588px #fff,
    90px 1746px #fff, 1212px 128px #fff, 760px 1232px #fff, 1934px 323px #fff,
    1286px 1159px #fff, 1134px 15px #fff, 1088px 269px #fff, 1475px 1126px #fff,
    1590px 466px #fff, 656px 577px #fff, 938px 892px #fff, 58px 1229px #fff,
    745px 218px #fff, 933px 877px #fff, 1256px 1534px #fff, 1229px 1190px #fff,
    1423px 1324px #fff, 887px 1473px #fff, 1970px 177px #fff, 1851px 1718px #fff,
    270px 546px #fff, 1752px 816px #fff, 1840px 957px #fff, 333px 1139px #fff,
    1308px 790px #fff, 1950px 1534px #fff, 118px 1302px #fff, 528px 1026px #fff,
    1988px 116px #fff, 379px 692px #fff, 1866px 403px #fff, 784px 61px #fff,
    988px 464px #fff, 1726px 342px #fff, 783px 783px #fff, 958px 850px #fff,
    995px 641px #fff, 1411px 1210px #fff, 248px 1483px #fff, 1856px 97px #fff,
    1732px 492px #fff, 817px 1977px #fff, 333px 331px #fff, 1527px 1998px #fff,
    1355px 585px #fff, 1344px 802px #fff, 1702px 262px #fff, 228px 1463px #fff,
    1047px 736px #fff, 559px 490px #fff, 454px 27px #fff, 1896px 677px #fff,
    1007px 844px #fff, 204px 1975px #fff, 1852px 822px #fff, 811px 1430px #fff,
    1429px 506px #fff, 47px 683px #fff, 1302px 245px #fff, 168px 373px #fff,
    618px 105px #fff, 731px 291px #fff, 485px 920px #fff, 1432px 668px #fff,
    559px 114px #fff, 1269px 119px #fff, 1829px 1961px #fff, 45px 67px #fff,
    717px 399px #fff, 1154px 1273px #fff, 390px 333px #fff, 1720px 1185px #fff,
    522px 198px #fff, 867px 341px #fff, 420px 487px #fff, 1809px 927px #fff,
    337px 1055px #fff, 164px 1672px #fff, 156px 779px #fff, 1052px 1481px #fff,
    267px 1901px #fff, 1289px 1917px #fff, 140px 1379px #fff, 798px 1369px #fff,
    908px 1663px #fff, 754px 435px #fff, 1356px 302px #fff, 545px 1717px #fff,
    239px 318px #fff, 614px 1265px #fff, 563px 191px #fff, 1645px 1299px #fff,
    1943px 406px #fff, 845px 901px #fff, 1778px 224px #fff, 1006px 161px #fff,
    1657px 1277px #fff, 1774px 1406px #fff, 567px 690px #fff, 1391px 1050px #fff,
    241px 1138px #fff, 273px 497px #fff, 1207px 1707px #fff, 747px 1828px #fff,
    722px 1049px #fff, 521px 1855px #fff, 1401px 595px #fff, 1794px 1157px #fff,
    1040px 1649px #fff, 1037px 1025px #fff, 96px 1985px #fff, 512px 150px #fff,
    1471px 1916px #fff, 720px 61px #fff, 1404px 193px #fff, 1073px 1446px #fff,
    794px 798px #fff, 240px 73px #fff, 1107px 850px #fff, 309px 1018px #fff,
    1333px 452px #fff, 931px 325px #fff, 1701px 1407px #fff, 123px 1012px #fff,
    1117px 580px #fff, 82px 1467px #fff, 994px 1229px #fff, 1375px 1315px #fff,
    85px 768px #fff, 1661px 693px #fff, 1246px 1383px #fff, 992px 1471px #fff,
    1941px 863px #fff, 476px 1112px #fff, 1092px 306px #fff, 861px 487px #fff,
    1883px 1295px #fff, 523px 1267px #fff, 374px 1651px #fff, 1096px 1473px #fff,
    1077px 274px #fff, 229px 1220px #fff, 376px 1868px #fff, 476px 1080px #fff,
    597px 1613px #fff, 1116px 1727px #fff, 806px 1350px #fff, 1808px 200px #fff,
    964px 1852px #fff, 797px 903px #fff, 534px 1158px #fff, 1440px 1727px #fff,
    673px 798px #fff, 1739px 1565px #fff, 1498px 1985px #fff, 466px 1028px #fff,
    849px 1307px #fff, 541px 1265px #fff, 1213px 527px #fff, 1466px 448px #fff,
    1965px 1645px #fff, 737px 672px #fff, 886px 174px #fff, 395px 834px #fff,
    438px 685px #fff;
  animation: animStar 100s linear infinite;
}

#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 259px 68px #fff, 609px 228px #fff, 604px 589px #fff,
    1391px 644px #fff, 811px 1632px #fff, 934px 1300px #fff, 898px 1184px #fff,
    900px 1532px #fff, 762px 1068px #fff, 1216px 1899px #fff, 1880px 308px #fff,
    1607px 552px #fff, 652px 124px #fff, 1653px 378px #fff, 1155px 800px #fff,
    1275px 361px #fff, 1197px 260px #fff, 1725px 1456px #fff, 410px 523px #fff,
    373px 259px #fff, 992px 1913px #fff, 180px 900px #fff, 89px 914px #fff,
    1269px 570px #fff, 569px 34px #fff, 778px 296px #fff, 239px 1953px #fff,
    906px 1313px #fff, 641px 604px #fff, 1313px 1549px #fff, 590px 261px #fff,
    1953px 471px #fff, 1666px 1648px #fff, 588px 1593px #fff, 1134px 1588px #fff,
    90px 1746px #fff, 1212px 128px #fff, 760px 1232px #fff, 1934px 323px #fff,
    1286px 1159px #fff, 1134px 15px #fff, 1088px 269px #fff, 1475px 1126px #fff,
    1590px 466px #fff, 656px 577px #fff, 938px 892px #fff, 58px 1229px #fff,
    745px 218px #fff, 933px 877px #fff, 1256px 1534px #fff, 1229px 1190px #fff,
    1423px 1324px #fff, 887px 1473px #fff, 1970px 177px #fff, 1851px 1718px #fff,
    270px 546px #fff, 1752px 816px #fff, 1840px 957px #fff, 333px 1139px #fff,
    1308px 790px #fff, 1950px 1534px #fff, 118px 1302px #fff, 528px 1026px #fff,
    1988px 116px #fff, 379px 692px #fff, 1866px 403px #fff, 784px 61px #fff,
    988px 464px #fff, 1726px 342px #fff, 783px 783px #fff, 958px 850px #fff,
    995px 641px #fff, 1411px 1210px #fff, 248px 1483px #fff, 1856px 97px #fff,
    1732px 492px #fff, 817px 1977px #fff, 333px 331px #fff, 1527px 1998px #fff,
    1355px 585px #fff, 1344px 802px #fff, 1702px 262px #fff, 228px 1463px #fff,
    1047px 736px #fff, 559px 490px #fff, 454px 27px #fff, 1896px 677px #fff,
    1007px 844px #fff, 204px 1975px #fff, 1852px 822px #fff, 811px 1430px #fff,
    1429px 506px #fff, 47px 683px #fff, 1302px 245px #fff, 168px 373px #fff,
    618px 105px #fff, 731px 291px #fff, 485px 920px #fff, 1432px 668px #fff,
    559px 114px #fff, 1269px 119px #fff, 1829px 1961px #fff, 45px 67px #fff,
    717px 399px #fff, 1154px 1273px #fff, 390px 333px #fff, 1720px 1185px #fff,
    522px 198px #fff, 867px 341px #fff, 420px 487px #fff, 1809px 927px #fff,
    337px 1055px #fff, 164px 1672px #fff, 156px 779px #fff, 1052px 1481px #fff,
    267px 1901px #fff, 1289px 1917px #fff, 140px 1379px #fff, 798px 1369px #fff,
    908px 1663px #fff, 754px 435px #fff, 1356px 302px #fff, 545px 1717px #fff,
    239px 318px #fff, 614px 1265px #fff, 563px 191px #fff, 1645px 1299px #fff,
    1943px 406px #fff, 845px 901px #fff, 1778px 224px #fff, 1006px 161px #fff,
    1657px 1277px #fff, 1774px 1406px #fff, 567px 690px #fff, 1391px 1050px #fff,
    241px 1138px #fff, 273px 497px #fff, 1207px 1707px #fff, 747px 1828px #fff,
    722px 1049px #fff, 521px 1855px #fff, 1401px 595px #fff, 1794px 1157px #fff,
    1040px 1649px #fff, 1037px 1025px #fff, 96px 1985px #fff, 512px 150px #fff,
    1471px 1916px #fff, 720px 61px #fff, 1404px 193px #fff, 1073px 1446px #fff,
    794px 798px #fff, 240px 73px #fff, 1107px 850px #fff, 309px 1018px #fff,
    1333px 452px #fff, 931px 325px #fff, 1701px 1407px #fff, 123px 1012px #fff,
    1117px 580px #fff, 82px 1467px #fff, 994px 1229px #fff, 1375px 1315px #fff,
    85px 768px #fff, 1661px 693px #fff, 1246px 1383px #fff, 992px 1471px #fff,
    1941px 863px #fff, 476px 1112px #fff, 1092px 306px #fff, 861px 487px #fff,
    1883px 1295px #fff, 523px 1267px #fff, 374px 1651px #fff, 1096px 1473px #fff,
    1077px 274px #fff, 229px 1220px #fff, 376px 1868px #fff, 476px 1080px #fff,
    597px 1613px #fff, 1116px 1727px #fff, 806px 1350px #fff, 1808px 200px #fff,
    964px 1852px #fff, 797px 903px #fff, 534px 1158px #fff, 1440px 1727px #fff,
    673px 798px #fff, 1739px 1565px #fff, 1498px 1985px #fff, 466px 1028px #fff,
    849px 1307px #fff, 541px 1265px #fff, 1213px 527px #fff, 1466px 448px #fff,
    1965px 1645px #fff, 737px 672px #fff, 886px 174px #fff, 395px 834px #fff,
    438px 685px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1283px 835px #fff, 587px 1676px #fff, 658px 1725px #fff,
    1962px 1457px #fff, 274px 1819px #fff, 431px 1813px #fff, 667px 1371px #fff,
    1778px 1438px #fff, 926px 227px #fff, 878px 940px #fff, 1135px 506px #fff,
    1870px 408px #fff, 244px 1185px #fff, 1228px 1192px #fff, 1940px 1031px #fff,
    429px 679px #fff, 1112px 1755px #fff, 1258px 893px #fff, 894px 1421px #fff,
    1928px 1060px #fff, 1778px 1401px #fff, 1782px 1011px #fff, 331px 291px #fff,
    723px 1585px #fff, 523px 470px #fff, 1406px 1462px #fff, 736px 1956px #fff,
    480px 476px #fff, 343px 383px #fff, 282px 1782px #fff, 1001px 480px #fff,
    1995px 962px #fff, 952px 413px #fff, 1988px 451px #fff, 323px 603px #fff,
    1401px 1204px #fff, 1792px 570px #fff, 940px 408px #fff, 725px 993px #fff,
    219px 1943px #fff, 1289px 832px #fff, 476px 172px #fff, 858px 1029px #fff,
    14px 1153px #fff, 798px 564px #fff, 492px 1317px #fff, 1470px 1971px #fff,
    191px 1489px #fff, 1657px 759px #fff, 457px 129px #fff, 387px 912px #fff,
    1607px 1231px #fff, 790px 873px #fff, 474px 1941px #fff, 1142px 1340px #fff,
    1777px 682px #fff, 470px 1453px #fff, 1785px 1497px #fff, 1598px 1125px #fff,
    937px 163px #fff, 714px 1400px #fff, 1323px 660px #fff, 1454px 124px #fff,
    371px 639px #fff, 460px 1093px #fff, 830px 163px #fff, 1384px 1044px #fff,
    933px 1516px #fff, 825px 300px #fff, 132px 200px #fff, 795px 1768px #fff,
    520px 233px #fff, 1152px 536px #fff, 1746px 321px #fff, 1248px 1330px #fff,
    1813px 756px #fff, 853px 431px #fff, 895px 1355px #fff, 336px 1259px #fff,
    1107px 1762px #fff, 460px 14px #fff, 281px 1626px #fff, 552px 95px #fff,
    362px 361px #fff, 1310px 787px #fff, 655px 254px #fff, 1203px 646px #fff,
    275px 1747px #fff, 389px 1605px #fff, 868px 434px #fff, 504px 129px #fff,
    539px 479px #fff, 1097px 1231px #fff, 1745px 9px #fff, 223px 811px #fff,
    1532px 1949px #fff, 1809px 631px #fff, 866px 565px #fff, 1544px 524px #fff,
    1566px 850px #fff;
  animation: animStar 150s linear infinite;
}

#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1283px 835px #fff, 587px 1676px #fff, 658px 1725px #fff,
    1962px 1457px #fff, 274px 1819px #fff, 431px 1813px #fff, 667px 1371px #fff,
    1778px 1438px #fff, 926px 227px #fff, 878px 940px #fff, 1135px 506px #fff,
    1870px 408px #fff, 244px 1185px #fff, 1228px 1192px #fff, 1940px 1031px #fff,
    429px 679px #fff, 1112px 1755px #fff, 1258px 893px #fff, 894px 1421px #fff,
    1928px 1060px #fff, 1778px 1401px #fff, 1782px 1011px #fff, 331px 291px #fff,
    723px 1585px #fff, 523px 470px #fff, 1406px 1462px #fff, 736px 1956px #fff,
    480px 476px #fff, 343px 383px #fff, 282px 1782px #fff, 1001px 480px #fff,
    1995px 962px #fff, 952px 413px #fff, 1988px 451px #fff, 323px 603px #fff,
    1401px 1204px #fff, 1792px 570px #fff, 940px 408px #fff, 725px 993px #fff,
    219px 1943px #fff, 1289px 832px #fff, 476px 172px #fff, 858px 1029px #fff,
    14px 1153px #fff, 798px 564px #fff, 492px 1317px #fff, 1470px 1971px #fff,
    191px 1489px #fff, 1657px 759px #fff, 457px 129px #fff, 387px 912px #fff,
    1607px 1231px #fff, 790px 873px #fff, 474px 1941px #fff, 1142px 1340px #fff,
    1777px 682px #fff, 470px 1453px #fff, 1785px 1497px #fff, 1598px 1125px #fff,
    937px 163px #fff, 714px 1400px #fff, 1323px 660px #fff, 1454px 124px #fff,
    371px 639px #fff, 460px 1093px #fff, 830px 163px #fff, 1384px 1044px #fff,
    933px 1516px #fff, 825px 300px #fff, 132px 200px #fff, 795px 1768px #fff,
    520px 233px #fff, 1152px 536px #fff, 1746px 321px #fff, 1248px 1330px #fff,
    1813px 756px #fff, 853px 431px #fff, 895px 1355px #fff, 336px 1259px #fff,
    1107px 1762px #fff, 460px 14px #fff, 281px 1626px #fff, 552px 95px #fff,
    362px 361px #fff, 1310px 787px #fff, 655px 254px #fff, 1203px 646px #fff,
    275px 1747px #fff, 389px 1605px #fff, 868px 434px #fff, 504px 129px #fff,
    539px 479px #fff, 1097px 1231px #fff, 1745px 9px #fff, 223px 811px #fff,
    1532px 1949px #fff, 1809px 631px #fff, 866px 565px #fff, 1544px 524px #fff,
    1566px 850px #fff;
}

.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner #bubble-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 1;
}

.bubble {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5),
    inset 0px 10px 30px 5px rgb(165, 165, 250);
  -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5),
    inset 0px 10px 30px 5px rgb(139, 139, 236);

  height: 200px;
  position: absolute;
  width: 200px;
}

.bubbleOne {
  -webkit-animation: bubblesAnimation 35s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 35s linear infinite,
    sides 2s ease-in-out infinite alternate;
  animation: bubblesAnimation 35s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 1%;
  top: 60%;

  -webkit-transform: scale(0.27);
  -moz-transform: scale(0.27);
  transform: scale(0.27);
}
.bubbleTwo {
  -webkit-animation: bubblesAnimation 39s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 20s linear infinite,
    sides 4s ease-in-out infinite alternate;
  animation: bubblesAnimation 39s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 5%;
  top: 90%;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.4);
  transform: scale(0.8);
}
.bubbleThree {
  -webkit-animation: bubblesAnimation 36s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 28s linear infinite,
    sides 2s ease-in-out infinite alternate;
  animation: bubblesAnimation 36s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 10%;
  top: 40%;

  -webkit-transform: scale(0.55);
  -moz-transform: scale(0.7);
  transform: scale(0.55);
}
.bubbleFour {
  -webkit-animation: bubblesAnimation 32s linear infinite,
    sides 3s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 22s linear infinite,
    sides 4s ease-in-out infinite alternate;
  animation: bubblesAnimation 32s linear infinite,
    sides 3s ease-in-out infinite alternate;

  left: 20%;
  top: 0;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.3);
  transform: scale(0.8);
}
.bubbleFive {
  -webkit-animation: bubblesAnimation 39s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 29s linear infinite,
    sides 2s ease-in-out infinite alternate;
  animation: bubblesAnimation 39s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 30%;
  top: 50%;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.5);
  transform: scale(0.4);
}
.bubbleSix {
  -webkit-animation: bubblesAnimation 31s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 21s linear infinite,
    sides 3s ease-in-out infinite alternate;
  animation: bubblesAnimation 31s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 50%;
  top: 40%;

  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.8);
  transform: scale(0.9);
}
.bubbleSeven {
  -webkit-animation: bubblesAnimation 30s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 40s linear infinite,
    sides 2s ease-in-out infinite alternate;
  animation: bubblesAnimation 30s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 65%;
  top: 70%;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.4);
  transform: scale(0.3);
}
.bubbleEight {
  -webkit-animation: bubblesAnimation 32s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 22s linear infinite,
    sides 3s ease-in-out infinite alternate;
  animation: bubblesAnimation 32s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 80%;
  top: 10%;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.3);
  transform: scale(0.4);
}
.bubbleNine {
  -webkit-animation: bubblesAnimation 41s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 29s linear infinite,
    sides 4s ease-in-out infinite alternate;
  animation: bubblesAnimation 41s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 57%;
  top: 74%;

  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.9);
  transform: scale(0.85);
}
.bubbleTen {
  -webkit-animation: bubblesAnimation 36s linear infinite,
    sides 2s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 26s linear infinite,
    sides 2s ease-in-out infinite alternate;
  animation: bubblesAnimation 36s linear infinite,
    sides 2s ease-in-out infinite alternate;

  left: 80%;
  top: 75%;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.8);
  transform: scale(0.6);
}
.bubbleEleven {
  -webkit-animation: bubblesAnimation 39s linear infinite,
    sides 3s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 26s linear infinite,
    sides 2s ease-in-out infinite alternate;
  animation: bubblesAnimation 39s linear infinite,
    sides 3s ease-in-out infinite alternate;

  left: 25%;
  top: 80%;

  -webkit-transform: scale(0.48);
  -moz-transform: scale(0.8);
  transform: scale(0.48);
}
.bubbleTwelve {
  -webkit-animation: bubblesAnimation 36s linear infinite,
    sides 3s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 26s linear infinite,
    sides 3s ease-in-out infinite alternate;
  animation: bubblesAnimation 36s linear infinite,
    sides 3s ease-in-out infinite alternate;

  left: 45%;
  top: 60%;

  -webkit-transform: scale(0.69);
  -moz-transform: scale(0.8);
  transform: scale(0.69);
}
.bubbleThirteen {
  -webkit-animation: bubblesAnimation 35s linear infinite,
    sides 3s ease-in-out infinite alternate;
  -moz-animation: bubblesAnimation 26s linear infinite,
    sides 3s ease-in-out infinite alternate;
  animation: bubblesAnimation 35s linear infinite,
    sides 3s ease-in-out infinite alternate;

  left: 50%;
  top: 60%;

  -webkit-transform: scale(0.15);
  -moz-transform: scale(0.8);
  transform: scale(0.15);
}

@keyframes bubblesAnimation {
  0% {
    margin-top: 70px;
  }
  100% {
    margin-top: -100%;
  }
}

@keyframes sides {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
